/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import { type RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [{
  path: '/',
  name: 'Home',
  component: async () => await import('@/components/landing/HomePage/HomePage.vue'),
  meta: {
    isOpenPage: true,
    title: 'X-Ray - Online Person Search',
    description:
      'Find information from open surces about people with X-Ray, do a background check to stay safe and make informed decisions.',
    noIndex: false
  }
},
{
  path: '/invite/',
  name: 'Home - Invite',
  component: async () => await import('@/components/landing/HomePage/HomePage.vue'),
  children: [{
    path: ':inviteID/',
    component: async () => await import('@/components/landing/HomePage/HomePage.vue')

  }],
  meta: {
    isOpenPage: true,
    title: 'X-Ray - Online Person Search',
    description:
      'Find information from open surces about people with X-Ray, do a background check to stay safe and make informed decisions.',
    noIndex: false

  },
  props: true
},
{
  path: '/about-us/',
  name: 'About Us',

  component: async () => await import('@/components/landing/AboutUs/AboutUsPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'About Us | X-Ray',
    description:
      'X-Ray Contact was created in Ukraine. The purpose of this product is to help users fight against fraud and form trusting interpersonal relationships in business.',
    noIndex: false
  }
}, {
  path: '/russian-search/',
  name: 'Free russian Search',

  component: async () => await import('@/components/landing/RussianSearch/RussianSearchPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Free russian Search | X-Ray',
    description:
      'X-Ray Contact was created in Ukraine. The purpose of this product is to help users fight against fraud and form trusting interpersonal relationships in business.',
    noIndex: false
  }
},
{
  name: 'Personal',
  path: '/personal/',
  component: async () => await import('@/components/landing/CaseStudies/PersonalPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'X-Ray for Personal Needs',
    description:
      'Find information from open surces about people with X-Ray, do a background check to stay safe and make informed decisions.',
    noIndex: false
  }
},
{
  name: 'Recruiters',
  path: '/recruiters/',
  component: async () => await import('@/components/landing/CaseStudies/RecruitersPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'X-Ray for Recruiters',
    description:
      'Find information from open surces about people with X-Ray, do a background check to stay safe and make informed decisions.',
    noIndex: false
  }
},
{
  name: 'Business',
  path: '/business/',
  component: async () => await import('@/components/landing/CaseStudies/BusinessPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'X-Ray for Business',
    description:
      'Find information from open surces about people with X-Ray, do a background check to stay safe and make informed decisions.',
    noIndex: false
  }
},
{
  path: '/contact-us/',
  name: 'Contact Us',

  component: async () => await import('@/components/landing/ContactUs/ContactUsPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Contact Us | X-Ray',
    description:
      'Any questions left? Leave your message and our team will contact you in no time.',
    noIndex: false
  }
},
{
  path: '/faq/',
  name: 'Frequently Asked Questions',

  component: async () => await import('@/components/landing/FAQ/FAQPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Frequently Asked Questions | X-Ray',
    description:
      'On this page, we have collected answers to the most common questions regarding the X-Ray online tool.',
    noIndex: false
  }
},
{
  path: '/opt-out/',
  name: 'Profile Opt Out',

  component: async () => await import('@/components/landing/OptOut/OptOutPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Opt Out | X-Ray',
    noIndex: false
  }
},
{
  path: '/opt-out-us/',
  name: 'Do Not Sell/ Share My Personal Information',

  component: async () => await import('@/components/landing/OptOut/OptOutUsPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Do not sell/share my personal information | X-Ray',
    noIndex: false
  }
},
{
  path: '/terms-of-use/',
  name: 'Terms of Use',

  component: async () => await import('@/components/landing/Terms/TermsOfUsePage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Terms of Usage | X-Ray',
    description:
      'On this page you will find everything you need to know about terms and conditions of using our website X-Ray.contact ',
    noIndex: false
  }
},
{
  path: '/privacy-policy/',
  name: 'Privacy Policy',

  component: async () => await import('@/components/landing/Privacy/PrivacyPolicyPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Privacy Policy | X-Ray',
    description:
      'By using the X-Ray website, you accept and agree to our Policy and consent to the collection and use of personal data as described in the Policy. Please read it carefully.',
    noIndex: false
  }
},
{
  name: 'Sanctions Screening',
  path: '/sanctions-screening/',
  meta: {
    title: 'Sanctions Screening',
    isOpenPage: true,
    showBreadcrumbs: true,
    noIndex: false,
    description:
      'Can you check a person for sanctions? Can you find out more about the company’s legal penalties? Is X-Ray Contact Sanctions Check free? All your questions about the service are answered in one place here. '

  },
  component: async () => await import('@/components/landing/Features/SanctionsScreening.vue')

},
{
  name: 'Reverse Email Search',
  path: '/reverse-email-lookup/',
  meta: {
    title: 'Reverse Email Address Search: Learn More About Contacts',
    isOpenPage: true,
    showBreadcrumbs: true,
    noIndex: false,
    description:
      'Wondering, “How can I find someone by their email address?” Discover how to lookup email address with our X-Ray reverse email address search. Find out more here.'

  },
  component: async () => await import('@/components/landing/Features/ReverseEmailSearch.vue')

},
{
  name: 'Reverse Phone Search',
  path: '/reverse-phone-lookup/',
  meta: {
    title:
      "Reverse Phone Lookup: Find Out Who's Calling in Just a Few Clicks",
    isOpenPage: true,
    showBreadcrumbs: true,
    noIndex: false,

    description: 'Discover what you can find with a reverse phone number lookup. Can it show text messages? Is our X-Ray reverse phone address search free? Learn how to track numbers and discern spam callers.'

  },
  component: async () => await import('@/components/landing/Features/ReversePhoneSearch.vue')

}, {
  name: 'Reverse Name Search',
  path: '/reverse-name-lookup/',
  meta: {
    title: 'Reverse Name Search: Find Anyone by a Name',
    isOpenPage: true,
    showBreadcrumbs: true,
    noIndex: false,
    description: 'Wondering, "How do I reverse search a username?" or "How to find someone\'s name from a username?" Discover the secrets of the X-Ray reverse username search for free.'

  },
  component: async () => await import('@/components/landing/Features/ReverseNameSearch.vue')

},
{
  name: 'Reverse Username Search',
  path: '/reverse-username-lookup/',
  meta: {
    title: 'Reverse Username Search: Reveal Someone’s Digital Footprint in No Time',
    isOpenPage: true,
    showBreadcrumbs: true,
    noIndex: false,
    description: 'Reveal answers to the most poignant questions about our Reverse Username Search service called Username Check. How can it benefit your business? Can I find an anonymous user? Can such a search disclose fake accounts?'

  },
  component: async () => await import('@/components/landing/Features/ReverseUsernameSearch.vue')

}, {
  name: 'Reverse Image Search',
  path: '/reverse-image-lookup/',
  meta: {
    title: 'Reverse Image Search: Unveil the Online Mysteries in Pictures',
    isOpenPage: true,
    showBreadcrumbs: true,
    noIndex: false,
    description: 'Ever wondered if X-Ray Reverse Image Search is free? Want to reverse image search a person? Learn how to search for someone by picture on your phone here.'

  },
  component: async () => await import('@/components/landing/Features/ReverseImageSearch.vue')

},
// {
//   path: '/new-pricing/',
//   name: 'New Pricing',

//   component: async () => await import('@/components/landing/Pricing/NewPricingPage.vue'),

//   meta: {
//     isOpenPage: true,
//     showBreadcrumbs: true,
//     title: 'Pricing | X-Ray',
//     description:
//       'Credits or a subscription? Choose between free, starter, standard and pro pricing plans to cover your needs.',

//     noIndex: false
//   }
// },

{
  path: '/pricing/',
  name: 'Pricing',

  component: async () => await import('@/components/landing/Pricing/PricingPage.vue'),

  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Pricing | X-Ray',
    description:
      'Credits or a subscription? Choose between free, starter, standard and pro pricing plans to cover your needs.',

    noIndex: false
  }
},
{
  path: '/log-in/',
  name: 'Log in',
  component: async () => await import('@/components/landing/Auth/SignInPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Log in | X-Ray',
    description: '',
    noIndex: false
  }
},
{
  path: '/log-in/:token/',

  component: async () => await import('@/components/landing/Auth/SignInPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Log in | X-Ray',
    description: '',
    noIndex: false
  }
},
{
  path: '/forgot-password/',
  name: 'Forgot password',
  component: async () => await import('@/components/landing/Auth/ForgotPasswordPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Forgot password? | X-Ray',
    description: '',
    noIndex: false
  }
}, {
  path: '/forgot-password/:token/',

  component: async () => await import('@/components/landing/Auth/ForgotPasswordPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: false,
    title: 'Forgot password? | X-Ray',
    description: '',
    noIndex: false
  }
},
{
  path: '/sign-up/',
  name: 'Sign up',
  component: async () => await import('@/components/landing/Auth/SignUpPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Registration | X-Ray',
    description: '',
    noIndex: false
  }
},
{
  path: '/platform/',
  name: 'Platform',
  redirect: { name: 'Search' },

  children: [
    {
      path: 'admin/',
      name: 'Admin',
      component: async () => await import('@/components/platform/Admin/AdminPage.vue'),
      redirect: { name: 'Stats' },

      children: [{
        path: 'stats/',
        name: 'Stats',
        component: async () => await import('@/components/platform/Admin/StatsPage.vue'),
        meta: {
          isAdmin: true
        }
      }, {
        path: 'expenses/',
        name: 'Expenses',
        component: async () => await import('@/components/platform/Admin/ExpencesPage.vue'),
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'user-management/',
        name: 'User management',
        component: async () => await import('@/components/platform/Admin/UserManagementPage.vue'),
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'invites/',
        name: 'Invites',
        component: async () => await import('@/components/platform/Admin/InvitesPage.vue'),
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'filter-groups/',
        name: 'Filter groups',
        component: async () => await import('@/components/platform/Admin/FilterGroupsPage.vue'),
        meta: {
          isAdmin: true
        }
      }, {
        path: 'test-groups/',
        name: 'Test groups',
        component: async () => await import('@/components/platform/Admin/TestGroupsPage.vue'),
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'blacklist/',
        name: 'Blacklist',
        component: async () => await import('@/components/platform/Admin/BlacklistPage.vue'),
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'exports/',
        name: 'Exports',
        component: async () => await import('@/components/platform/Admin/ExportsPage.vue'),
        meta: {
          isAdmin: true
        }
      }
      ],
      meta: {
        showDescription: true,
        showTitle: true,
        isAdmin: true,
        isPlatform: true,
        description: 'Perform identity search using email address, phone number, social links or image.'
      }
    },
    {
      path: 'username-search/',
      name: 'Username Search',
      component: async () => await import('@/components/platform/UsernameSearch/UsernameSearchPage.vue'),
      meta: {
        showDescription: true,
        showTitle: true,
        description: 'Perform identity search by username.',
        title: 'Username Search | X-Ray',
        cached: false
      }
    },
    {
      path: 'search/',
      name: 'Search',
      component: async () => await import('@/components/platform/Search/SearchPage.vue'),
      meta: {
        showDescription: true,
        showTitle: true,
        description: 'Perform identity search using email address, phone number, social links or image.',
        title: 'Search | X-Ray'
      }
    },
    {
      name: 'Name search results',
      path: 'search/name/:taskId/',
      component: async () => await import('@/components/platform/Profile/Name/NameSearchPage.vue'),
      meta: {
        showDescription: false,
        showTitle: true,
        description: 'Perform identity search using email address, phone number, social links or image.',
        title: 'Name search list | X-Ray'
      }
    },
    {
      path: 'russian-search/',
      name: 'russian Search',
      component: async () => await import('@/components/platform/Search/RussianSearchPage.vue'),
      meta: {
        showDescription: true,
        showTitle: true,
        description: 'Perform russian pigs search using email address, phone number, social links for free.',
        title: 'russian search | X-Ray'
      }
    },
    {
      path: 'tg-search/',
      name: 'TG-bot Search',
      component: async () => await import('@/components/platform/TgBotsSearch/TgBotsSearchPage.vue'),
      meta: {
        showDescription: true,
        showTitle: true,
        description: 'Perform TG-bot search',
        title: 'TG-bot Search | X-Ray'
      }
    },
    {
      path: 'sanctions-check/',
      name: 'Sanctions Check',
      component: async () => await import('@/components/platform/SanctionsSearch/SanctionsSearchPage.vue'),
      meta: {
        showDescription: true,
        showTitle: true,
        description: 'Perform Sanctions List check',
        title: 'Sanctions check | X-Ray'
      }
    },

    {
      path: 'history/',
      name: 'History',
      component: async () => await import('@/components/platform/History/HistoryPage.vue'),
      redirect: { name: 'Profiles history', params: { page: 1 } },
      children: [
        {
          name: 'Profiles history',
          path: 'profiles/:page/',
          component: async () => await import('@/components/platform/History/HistoryList.vue'),
          meta: {
            title: route => `Profiles History | Page ${route.params.page} | X-Ray`
          }

        },
        {

          path: 'images/',
          redirect: { name: 'Images history', params: { page: 1 } }

        },
        {
          name: 'Images history',
          path: 'images/:page/',
          component: async () => await import('@/components/platform/History/HistoryImageList.vue'),
          meta: {
            title: route => `Image History | Page ${route.params.page} | X-Ray`
          }

        }
      ],
      meta: {
        showDescription: true,
        showTitle: true,
        description: 'Your search history displays here.',
        title: 'History | X-Ray'
      },
      // beforeEnter: (to) => { to.meta.title = `History (page ${to.params.page as string}) | X-Ray` },
      params: { searchType: 'all', page: 1, size: 10 }

    },
    {
      path: 'history/profiles/',
      redirect: { name: 'History' }
    },
    {
      path: 'profiles/',
      name: 'Profiles',

      redirect: { name: 'History' },
      children: [
        {

          path: 'person/',
          redirect: { name: 'History' }
        },
        {

          path: 'image/',
          redirect: { name: 'History' }
        },
        {
          name: 'Search result',
          path: 'person/:personId/',
          component: async () => await import('@/components/platform/Profile/ProfileResults.vue'),
          meta: {
            title: 'Search results | X-Ray'
          }
        },
        {
          name: 'Search result - Safe',
          path: 'person/:personId/:safe',
          component: async () => await import('@/components/platform/Profile/ProfileResults.vue'),
          meta: {
            title: 'Search results | X-Ray'
          }
        },
        {
          name: 'Image results',
          path: 'image/:imageId/',
          component: async () => await import('@/components/platform/Profile/Image/ImageResults.vue'),
          meta: {
            title: 'Image search results | X-Ray'
          }
        }
      ],
      meta: {
        description: '',
        showDescription: false,
        showTitle: true,
        meta: {
          title: 'Search results | X-Ray'
        }
      }
    },
    {
      path: 'store/',
      name: 'Store',

      component: async () => await import('@/components/platform/Store/StorePage.vue'),
      children: [

        {

          path: 'payment-success/',
          component: async () => await import('@/components/landing/Pricing/PurchaseCards.vue'),
          meta: {
            type: 'credit',
            description: 'Credit Packages give you a specific amount of both Basic and Advanced credits. These credits don’t expire and are available as long as you remain a user of our service.',
            successPayment: true
          }
        }
      ],
      meta: {
        description: 'Get credits',
        showDescription: false,
        showTitle: true,
        title: 'Store | X-Ray'
      }
    },
    {
      path: 'account/',
      name: 'Account',
      component: async () => await import('@/components/platform/Account/AccountPage.vue'),
      redirect: { name: 'Personal settings' },
      children: [
        {
          name: 'Personal settings',
          path: 'personal-settings/',
          meta: {
            description: 'Manage your personal information here.',
            title: 'Personal preferences | X-Ray'
          },
          component: async () => await import('@/components/platform/Account/Personal/PersonalSettingsPage.vue')
        },
        {
          name: 'Billing',
          path: 'billing/',
          meta: {
            description: 'Credits and Subscription Plan information.',
            title: 'Billing preferences | X-Ray'
          },
          component: async () => await import('@/components/platform/Account/Billing/BillingPage.vue')
        },
        {
          name: 'Usage stats',
          path: 'usage-stats/',
          meta: {
            description: 'Your credits history.',
            title: 'Usage stats | X-Ray'
          },
          component: async () => await import('@/components/platform/Account/CreditsHistory/CreditsHistoryPage.vue')
        },
        {
          name: 'API',
          path: 'api/',
          meta: {
            description: 'Manage your API keys here.',
            title: 'API preferences | X-Ray'
          },
          component: async () => await import('@/components/platform/Account/API/ApiPage.vue')
        }
      ],
      meta: {
        description: 'Account preferences',
        showDescription: false,
        showTitle: false,
        noPadding: true,
        title: 'Account preferences | X-Ray'
      }
    },
    {
      name: 'Advanced credit survey',
      path: 'advanced-credit-survey/',
      component: async () => await import('@/components/platform/Surveys/AdvancedCreditSurvey.vue')
    },
    {
      name: 'Subscription feedback survey',
      path: 'unsub-survey/',
      component: async () => await import('@/components/platform/Surveys/UnsubSurvey.vue')
    }
  ],
  meta: {
    isOpenPage: false,
    isPlatform: true,
    showTitle: true,
    title: 'Platform | X-Ray',
    description:
      'Find information from open surces about people with X-Ray, do a background check to stay safe and make informed decisions.',
    noIndex: true
  }

},
{
  name: 'Not found',
  path: '/404/',
  component: async () => await import('@/components/landing/404/NotFoundPage.vue'),
  meta: {
    isOpenPage: true,
    showBreadcrumbs: true,
    title: 'Not found | X-Ray',
    description: 'Page does not exist',
    noIndex: false
  }
},
{
  path: '/:pathMatch(.*)*',
  redirect: { name: 'Not found' }

}
]
export default routes
